.react-datepicker__input-container input {
  display: block;
  width: inherit; /* Adjust the width value as needed */
  height: 40px; /* Adjust the height value as needed */
  background-color: transparent;
  color: #606277;
  border: 1px solid white;
  border-radius: 5px;
  position: relative;
  padding-left: 15px;
}
